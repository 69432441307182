<template>
    <div class="container">
        <div class="card-group">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <h3>{{ statistic.user.max_device === -1 ? 'Tidak Terbatas' : statistic.user.max_device }}</h3>
                            <h6 class="card-subtitle">Maksimal Perangkat</h6>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Column -->
            <!-- Column -->
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <h3>{{ statistic.user.max_cs === -1 ? 'Tidak Terbatas' : statistic.user.max_cs }}</h3>
                            <h6 class="card-subtitle">Maksimal CS</h6>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <h3>{{ statistic.user.max_link_rotator === -1 ? 'Tidak Terbatas' : statistic.user.max_link_rotator }}</h3>
                            <h6 class="card-subtitle">Maksimal Link Rotator</h6>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Column -->
            <!-- Column -->
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <h3>{{ momentFormat(statistic.user.expired_at, 'DD MMMM YYYY HH:mm') }} WIB</h3>
                            <h6 class="card-subtitle">Tanggal Expired</h6>
                        </div>
                        <div class="col-12">
                            <div class="progress">
                                <div class="progress-bar bg-danger" role="progressbar" style="width: 100%; height: 6px;" aria-valuenow="25" aria-valuemin="0"
                                    aria-valuemax="100"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Column -->
        </div>

        <!-- SUMMARY 2 -->
        <div class="row">
            <div class="col-12 col-md-8">
                <div class="card bg-primary text-white">
                    <div class="card-body">
                        <div class="d-flex">
                            <div class="mr-3 align-self-center">
                                <h1 class="text-white">
                                    <i class="ti-user"></i>
                                </h1>
                            </div>
                            <div>
                                <h4 class="card-title">Nama Lengkap</h4>
                                <h6 class="text-white op-5">{{ statistic.user.name }}</h6>
                            </div>

                        </div>

                        
                    </div>
                </div>
                <div class="card bg-cyan text-white mb-0">
                    <div class="card-body">
                        <div class="d-flex">
                            <div class="mr-3 align-self-center">
                                <h1 class="text-white">
                                    <i class="ti-email"></i>
                                </h1>
                            </div>
                            <div>
                                <h4 class="card-title">Email</h4>
                                <h6 class="text-white op-5">{{ statistic.user.email }}</h6>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="col-12 col-md-4 mt-3 mt-md-0">
                <div class="card mb-0 bg-success h-100">
                    <div class="card-body text-white">
                        <div class="d-flex flex-column justify-content-between h-100">
                            <div class="align-self-center display-6">
                                <!-- <img src="../../assets/images/wa-logo-2.png" alt="Whatsapp" style="width: 50px;"> -->
                            </div>
                            
                            <div class="align-self-center">
                                <h2 class="font-medium mb-0">{{ statistic.device.total_records }}</h2>
                            </div>

                            <div class="p-3 align-self-center">
                                <h4 class="m-b-0">Whatsapp Connected</h4>
                                <span>Total Whatsapp Terkoneksi</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment'

    export default {
        components: {
        },
        data() {
            return {
                statistic: {
                    device: {
                        total_records: 0,
                    },
                    percentage: {
                        max_device: 0,
                    },
                    user: {
                        id: 0,
                        name: "",
                        email: "",
                        phone: "",
                        max_message: 0,
                        max_device: 0,
                        max_cs: 0,
                        package_id: 0,
                        level: "",
                        owner: 0,
                        expired_at: "",
                        created_at: "",
                        updated_at: "",
                        package: {
                            id: 0,
                            name: "",
                            lisensi: 0,
                            message_quota: 0,
                            max_device: 0,
                            max_cs: 0,
                            data: null,
                            duration: 0,
                            price: 0,
                            hide: null,
                            created_at: "",
                            updated_at: ""
                        }
                    },
                }
            }
        },
        watch: {
        },
        mounted() {
            this.getStatictics();
        },
        methods: {
            async getStatictics() {
                let response = await this.$store.dispatch('user_statistics', {});
                this.statistic = {
                    ...response.data.data,
                    percentage: {
                        max_device: response.data.data.user.max_device === -1 ? 100 : (response.data.data.device.total_records / response.data.data.user.max_device) * 100
                    }
                };
            },
            momentFormat(date, format = "YYYY-MM-DD HH:mm:ss") {
                return moment.utc(date).local().format(format)
            }
        }
    }
</script>

<style scoped>
    .element-top-right {
        position: absolute;
        top: 20px;
        right: 20px;
        z-index: 10;

    }
</style>
